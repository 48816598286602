<template>
  <div class="ml-5">
    <!--
    <v-container>
      <SiteHeaderComponent header="Changelog"></SiteHeaderComponent>
    </v-container>
    -->
    <v-container class="py-4">
      <v-row class="pa-0 pl-2 mt-0">
        <v-col cols="12" md="12" xl="10" lg="10">
          <v-container
            class="overflow-y-auto"
            style="height: 70vh"
            ref="scrollableChangelogs"
          >
          <SiteHeaderComponent header="Changelog"></SiteHeaderComponent>
            <VueMarkdown
              :source="markdownContent"
              toc
              toc-anchor-class="scrollactive-item"
            ></VueMarkdown>
          </v-container>
        </v-col>
        <v-col cols="2" class="d-flex flex-column justify-start align-end">
          <v-timeline>
            <v-timeline-item
              v-for="content in tableOfContents"
              :key="content.slug"
              small
              right
              exact
              hide-dot
              class="mr-15 pa-1 "
              :class="{
                'primary--text font-weight-bold': content.hierarchy == 1,
                'pl-8': content.hierarchy == 2,
                'pl-16': content.hierarchy == 3
              }"
            >
              <div class="hoverCursor" @click="scrollTo(content)">
                <span>
                  {{ content.title }}
                </span>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import SiteHeaderComponent from '../../components/core/SiteHeaderComponent.vue'

export default {
  components: {
    VueMarkdown,
    SiteHeaderComponent
  },
  data: () => ({
    allVersions: ['2.0.0', '1.0.0'],
    markdownContent: ''
  }),
  computed: {
    tableOfContents() {
      var tableOfContents = []
      const headings = this.markdownContent.match(/^(#+) (.+)$/gm)
      if (headings) {
        tableOfContents = headings.map(heading => {
          const hierarchy = heading.match(/#/g).length
          const title = heading.replace(/^(#+) /, '')
          const sanitizedTitle = title
            .toLowerCase()
            .replace(/[^\w\säöüß-]/g, '') // Hier wurden ä, ö, ü, ß hinzugefügt
            .replace(/\s+/g, '-')

          const id = sanitizedTitle.replace(/^[^a-zA-Z]+/, 'a')
          const active = false
          return { title, hierarchy, id, active }
        })
      }

      return tableOfContents
    }
  },
  methods: {
    async getChangelog() {
      var changelog = ''
      for (var version of this.allVersions) {
        var fileName = 'v' + version.replace(/\./g, '-') + '.md'
        changelog += (await import(`@/docs/release/${fileName}`)).default + '\n'
      }
      return changelog
    },
    scrollTo(content) {
      const scrollableChangelogs = this.$refs.scrollableChangelogs
      const section = document.getElementById(content.id)

      if (scrollableChangelogs && section) {
        const scrollableChangelogsRect = scrollableChangelogs.getBoundingClientRect()
        const sectionRect = section.getBoundingClientRect()
        const scrollY =
          sectionRect.top -
          scrollableChangelogsRect.top +
          scrollableChangelogs.scrollTop
        scrollableChangelogs.scrollTo({ top: scrollY, behavior: 'smooth' })
      }
    }
  },
  watch: {},
  async mounted() {
    this.markdownContent = await this.getChangelog()
  }
}
</script>

<style scoped>
.hoverCursor:hover {
  cursor: pointer;
}
</style>
